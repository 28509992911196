a#edit-page {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 10px;
    background: #add8e682;
}
// this is for the inline editing of body & headlines
.pw-edit {
	cursor: pointer;
    padding: 10px;
    display: block;
    box-shadow: inset 0 0 0 4px blue;
	&:after {
		display: table;
		content: "";
	}
}
// max height for lightbox iframe, if not we can't see the save button
.ui-dialog {
	z-index: 200000 !important; 
	.ui-dialog-content {
		max-height: calc(100vh - 250px) !important;
	}
}
// areas that open lightboxes
.pw-modal{
    cursor: pointer;
    padding: 5px;
    text-align: center;
    color: #fff;
    font-weight: bold;
	font-size: 16px;
	height: 24px;
}

.pw-modal.edit-rows {
    text-align: center;
    display: block;
    margin: 0 auto;
    width: 140px;
    background: red;
    color: #fff;
}
// editable areas > extra divs I put to define/show areas we can edit
.editable-area {
	position: relative;
	padding-top: 34px;
	.pw-modal {
		position: absolute;
		top: 0;
		right: 0;
	}
	&.editable-row {
		box-shadow: inset 0 0 0px 4px orange;
		.pw-modal.edit-row {
			background: orange;
		}
	}
	&.editable-column {
		box-shadow: 0 0 0px 4px pink;
		.pw-modal.edit-column {
			background: pink;
		}
		.reusable-module .editable-column {
			box-shadow: 0 0 0px 4px green;
			.pw-modal.edit-column {
				background: green;
			}
		}
	}
}
a#edit-page {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 10px;
  background: #add8e682;
}

.pw-edit {
  cursor: pointer;
  padding: 10px;
  display: block;
  box-shadow: inset 0 0 0 4px blue;
}

.pw-edit:after {
  display: table;
  content: "";
}

.ui-dialog {
  z-index: 200000 !important;
}

.ui-dialog .ui-dialog-content {
  max-height: calc(100vh - 250px) !important;
}

.pw-modal {
  cursor: pointer;
  padding: 5px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  height: 24px;
}

.pw-modal.edit-rows {
  text-align: center;
  display: block;
  margin: 0 auto;
  width: 140px;
  background: red;
  color: #fff;
}

.editable-area {
  position: relative;
  padding-top: 34px;
}

.editable-area .pw-modal {
  position: absolute;
  top: 0;
  right: 0;
}

.editable-area.editable-row {
  box-shadow: inset 0 0 0px 4px orange;
}

.editable-area.editable-row .pw-modal.edit-row {
  background: orange;
}

.editable-area.editable-column {
  box-shadow: 0 0 0px 4px pink;
}

.editable-area.editable-column .pw-modal.edit-column {
  background: pink;
}

.editable-area.editable-column .reusable-module .editable-column {
  box-shadow: 0 0 0px 4px green;
}

.editable-area.editable-column .reusable-module .editable-column .pw-modal.edit-column {
  background: green;
}

